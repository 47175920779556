// @ts-nocheck

// the idea behind this approach is to enable testing in node env for jest
// this is the simplest solution for handling/organizing/tracking usage of browser APIs
import type { IEnvironment } from 'config/env';

import cypressEnv from './testing/cypress-env';

const _window = window;
const isCypressComponentTesting = () => window?.Cypress?.testingType === 'component';

export const detatchEnvFromWindow = () => {
  const environment: IEnvironment = isCypressComponentTesting() ? cypressEnv : _window?.environment;
  _window.environment = undefined;
  return environment;
};

export const URLSearchParams = _window.URLSearchParams;
export const URL = _window.URL;
export const localStorage = _window.localStorage;

// this is the bouncy balls preloader base64 encoding
// it is added to html template and being reused here
export const preloaderImgSource = _window.preloaderImgSource;

export const getModalPortalParent = () => document.getElementById('modal');

export const setGlobalFunction = (id: string, fn: (data: unkown) => void) => (window[id] = fn);
export const getGlobalFunction = (id: string) => window[id];

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/* getIframeOrigin()
 * borrowed from https://stackoverflow.com/a/66060655/4388593
 */
export const getIframeOrigin = () => {
  try {
    const locationAreDisctint = window.location !== window.parent.location;
    const parentOrigin = ((locationAreDisctint ? document.referrer : document.location) || '').toString();

    if (parentOrigin) {
      return new URL(parentOrigin).origin;
    }

    const currentLocation = document.location;

    if (currentLocation.ancestorOrigins && currentLocation.ancestorOrigins.length) {
      return currentLocation.ancestorOrigins[0];
    }

    return 'unknown';
  } catch {
    return 'error caught';
  }
};

export default _window;
