import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'reflect-metadata';
import 'utils/app-switcher';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import env from 'config/env';
import { enableMocking } from 'network/mocks/browser';
import { discardNoiseFromLogs } from 'utils/analytics/helpers';
import { getIframeOrigin, inIframe } from 'utils/window';

import App from './App';

if (env.DATADOG) {
  datadogRum.init({
    applicationId: env.DATADOG.APPLICATION_ID,
    clientToken: env.DATADOG.CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: env.DATADOG.SERVICE_NAME,
    env: env.DATADOG.VIRTUAL_ENV, // This is used to identify virtual env
    version: env.VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
  });

  datadogRum.startSessionReplayRecording();

  datadogLogs.init({
    clientToken: env.DATADOG.CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: env.DATADOG.SERVICE_NAME,
    forwardErrorsToLogs: true,
    env: env.DATADOG.VIRTUAL_ENV,
    sessionSampleRate: 100,
    version: env.VERSION,
    beforeSend: discardNoiseFromLogs,
  });
  datadogLogs.logger.setLevel('info');
  datadogLogs.logger.setHandler(['http', 'console', 'silent']);
  datadogLogs.setGlobalContextProperty('iframe.is_iframe', inIframe());
  datadogLogs.setGlobalContextProperty('iframe.origin', getIframeOrigin());
}

if (inIframe()) {
  ReactDOM.render(
    <>
      Seems like you are trying to load the app inside an Iframe. This is prevented due to security reasons. Please
      check if you have browser extensions that are doing this and remove them to continue.
    </>,
    document.getElementById('app'),
  );
} else {
  enableMocking(env.MOCKED).then(() => {
    ReactDOM.render(
      <StrictMode>
        <App />
      </StrictMode>,
      document.getElementById('app'),
    );
  });
}
