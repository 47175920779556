import { IEnvironment } from 'config/env';

const environment: IEnvironment = {
  TECH_CHECK_URL_TEMPLATE:
    'https://evc.ef.com/evc15/meeting/tools/teachertechcheck?proxyInfo=&centerCode=UK#ret={0};s=304615050;m=35754986;cc=en;host=axis.eflabs.io;canskip=true',
  ENTRY_STATUS_INTERVAL: 2e4,
  ACR_TODO_INTERVAL: 6e4,
  UNCLAIMED_LESSONS_INTERVAL: 1e4,
  LESSON_DEFINITION_INTERVAL: 6e4,
  AVAILABILITY_INTERVAL: 1e4,
  BOOKING_INTERVAL: 1e4,
  USER_INTERVAL: 6e5,
  DISCONNECT_AFTER: 3e5,
  GQL_URL: '',
  MEDIA_URL: '',
  SUPPORT_URL: 'https://ef-teachonline-helpcenter.force.com/s/',
  HELP_CENTER_URL: 'https://staging-englishlive.cs113.force.com/helpcenter',
  HC_PREVIEW_URL: 'https://evc.ef.com/evc15/meeting/home/hc-preview#/topic/',
  LOGIN: {
    SIGNIN_SIGNUP_USER_FLOW: 'B2C_1_TF_non_prod-2',
    RESET_USER_FLOW: 'B2C_1_teacher_first_non_prod_reset',
    AUTHORITY_BASE_URL: 'https://efiddev.b2clogin.com/efiddev.onmicrosoft.com/',
    CLIENT_ID: '6f1ca649-b440-4c02-8e43-f65e4e493ca5',
    TOKEN_SCOPE: 'https://efiddev.onmicrosoft.com/tfapi/tfapi.readwrite',
  },
  API_KEYS: {},
  EVC_TUNNELS: { CN_SH: '', US_NV: '' },
  TF_SVCGATEWAY_URL: '',
  ENV: 'dev',
};
export default environment;
