import env from 'config/env';
import { injectScript } from 'utils/miscellaneous';

// Increment this version every time there is a change to the appswitcher.js file (to bust cache).
const VERSION = '1';

let appSwitcherBaseUrl = 'https://cdn.dev.ef.studio';
if (env?.ENV === 'prod') {
  appSwitcherBaseUrl = 'https://cdn.ef.studio';
}

injectScript(`${appSwitcherBaseUrl}/appswitcher/appswitcher.js?v=${VERSION}`);
