import cypressEnv from 'utils/testing/cypress-env';

import { GITHUB_RUN_NUMBER, MOCKED } from './constants';
import {
  defaultConfig,
  devEliveQa,
  devOps,
  devOpsCn,
  devOpsEliveQa,
  devTF,
  devTFCn,
  localOps,
  localTF,
  prodOps,
  prodOpsCn,
  prodTF,
  prodTFCn,
  qaOpsCn,
  qaTFCn,
} from './envByHost';

export interface IEnvironment {
  ENV: 'dev' | 'prod' | 'elive';
  GQL_URL: string;
  MEDIA_URL: string;
  TF_SVCGATEWAY_URL: string;
  SUPPORT_URL: string;
  AVAILABILITY_INTERVAL: number;
  BOOKING_INTERVAL: number;
  USER_INTERVAL: number;
  ENTRY_STATUS_INTERVAL: number;
  ACR_TODO_INTERVAL: number;
  LESSON_DEFINITION_INTERVAL: number;
  UNCLAIMED_LESSONS_INTERVAL: number;
  DISCONNECT_AFTER: number;
  HELP_CENTER_URL: string;
  TECH_CHECK_URL_TEMPLATE: string;
  HC_PREVIEW_URL: string;
  LOGIN: {
    SIGNIN_SIGNUP_USER_FLOW: string;
    RESET_USER_FLOW: string;
    AUTHORITY_BASE_URL: string;
    CLIENT_ID: string;
    TOKEN_SCOPE: string;
  };
  MOCKED?: boolean;
  DATADOG?: {
    APPLICATION_ID: string;
    CLIENT_TOKEN: string;
    SERVICE_NAME: string;
    VIRTUAL_ENV: string;
  };
  API_KEYS: {
    amplitude?: string;
  };
  EVC_TUNNELS: {
    US_NV: string;
    CN_SH: string;
  };
  VERSION?: string; // we use build number as a version until find need/way to do semver
}

// @ts-ignore
const isCypressComponentTesting = () => window?.Cypress?.testingType === 'component';

export const getEnvByHostname = () => {
  if (isCypressComponentTesting()) {
    return cypressEnv;
  }

  const { hostname, port } = window.location;
  console.debug('loading env configuration for hostname', hostname); // eslint-disable-line no-console
  if (hostname === 'localhost') {
    if (port === '8081') {
      return localTF;
    }
    if (port === '8085') {
      return localOps;
    }
  }

  switch (hostname) {
    case 'efekta.io':
    case 'ef.studio':
    case 'teach.efekta.io':
    case 'teach.ef.studio':
      return prodTF;
    case 'ops-portal.efekta.io':
    case 'ops-portal.ef.studio':
      return prodOps;
    case 'dev.efekta.io':
    case 'dev.ef.studio':
    case 'teach.dev.efekta.io':
    case 'teach.dev.ef.studio':
    case 'feature-test-teach.ef.studio':
      return devTF;
    case 'elive-qa.efekta.io':
    case 'elive-qa.ef.studio':
      return devEliveQa;
    case 'dev-ops-portal.efekta.io':
    case 'ops-portal.dev.efekta.io':
    case 'ops-portal.dev.ef.studio':
    case 'feature-test-ops-portal.ef.studio':
      return devOps;
    case 'elive-qa-ops-portal.efekta.io':
    case 'elive-qa-ops-portal.ef.studio':
      return devOpsEliveQa;
    case 'teach.dev.ef.studio.ef.com.cn':
      return devTFCn;
    case 'ops-portal.dev.ef.studio.ef.com.cn':
      return devOpsCn;
    case 'elive-qa.ef.studio.ef.com.cn':
      return qaTFCn;
    case 'elive-qa-ops-portal.ef.studio.ef.com.cn':
      return qaOpsCn;
    case 'teach.ef.studio.ef.com.cn':
      return prodTFCn;
    case 'ops-portal.ef.studio.ef.com.cn':
      return prodOpsCn;
    default:
      return defaultConfig;
  }
};

const env: IEnvironment = getEnvByHostname();

env.VERSION = GITHUB_RUN_NUMBER || '0';
env.MOCKED = !!+MOCKED || false;

export const BE_URL = env?.TF_SVCGATEWAY_URL || '';
export const GQL_URL = env?.GQL_URL || 'http://localhost:8080/';

export default env;
